import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Line from "@ui/divider/line";
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from "@ui/tabs/layout-one";
import { SectionWrap,SectionTitle, ListGroupWrap } from "./style";
import TeamMember from "@components/team-member";
import { data } from "./data";
import PropTypes from "prop-types";
import List, { ListItem } from "@ui/list";


const DividerSection = (props) => {
	const { HeadingStyle, textStyle, executiveStyle, buttonTwoStyle, teamMemberStyle, ListThreeStyle } = props;

	return (
		<SectionWrap>
			<Container>
				<Row>
					<Col lg={6}>
						<Row>
							{data.map((team, i) => (
								<Col 
									md={5} 
									key={`team-${i}`}
									mb={["30px", null, "60px"]}
									>
									<TeamMember
										{...teamMemberStyle}
										{...executiveStyle}
										imageSrc={team.image}
										name={team.name}
										designation={team.designation}
										social={team.socials}
									/>
								</Col>
							))}
						</Row>
					</Col>
					<Col lg={4}>
						<SectionTitle>
							<Text {...textStyle}><h5>Baobao Song</h5>
							<p><span style={{fontStyle: 'italic'}}>PhD candidate</span><br></br>
							<span style={{fontStyle: 'italic'}}>HDR student of TULIP Lab</span><br></br></p>

							<p><span style={{fontWeight:'bold'}}>Postal Address:</span><br></br>
							<span style={{fontStyle: 'italic'}}>Faculty of Engineering and Information Technology</span><br></br>
							<span style={{fontStyle: 'italic'}}>University of Technology Sydney</span><br></br>
							<span style={{fontStyle: 'italic'}}>15 Broadway, Ultimo</span><br></br>
							<span style={{fontStyle: 'italic'}}>NSW 2007, Australia</span>
							</p>
							</Text>
						</SectionTitle>
					</Col>
					<Col lg={24}>
						<Heading  as="h6" mb="15px">About Me</Heading>
						<Text>My research interests are in the area of data science, privacy protection, and tourism/hospitality management.</Text>
						<Line mt="40px" mb="40px" borderWidth="2px" />
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col lg={12}>
						<Tabs defaultActiveKey="updates-tab">
							<TabHeader>
								<NavItem eventKey="updates-tab">Updates</NavItem>
								<NavItem eventKey="services-tab">Pro Services</NavItem>
								<NavItem eventKey="talks-tab">Invited Talks</NavItem>
								<NavItem eventKey="awards-tab">Awards & Patents</NavItem>
								<NavItem eventKey="grands-tab">Grants</NavItem>
							</TabHeader>
							<TabContent pt="50px">
								<TabPane eventKey="updates-tab">
									<p>TBD</p>
								</TabPane>
								<TabPane eventKey="services-tab">
									<p>TBD</p>
								</TabPane>
								<TabPane eventKey="talks-tab">
//						<ListGroupWrap>
//							<List {...ListThreeStyle}>
//								<ListItem>17/12/2021, <span style={{fontStyle: 'italic'}}>Graphical Neural Network for Hate Speech Detection in Social Network,</span> (keynote speech, deliver remotely), the 5th International Conference on Computational Systems and Information Technology for Sustainable Solutions (CSITSS2021), Bangalore, India</ListItem>
//								<ListItem>16/10/2021, <span style={{fontStyle: 'italic'}}>Graphical Neural Network for Hate Speech Detection in Social Network,</span> (keynote speech, deliver remotely), 2021 IEEE the 5th Information Technology, Networking, Electronic and Automation Control Conference (ITNEC2021), Xi'An, China</ListItem>
//								<ListItem>24/11/2020, <span style={{fontStyle: 'italic'}}>Privacy Preserving Techniques in Data Mining,</span> (keynote speech, deliver remotely), International Symposium on Significant Pandemic Implications on Science, Technology, Humanities and Education, Manipal, India</ListItem>
//								<ListItem>04/10/2019, <span style={{fontStyle: 'italic'}}>Group Behavior Analysis in Tourism,</span> (opening address), Excutive Development Programme on Data Analysis and Smart Tourism (EAP2019), University of Macau, Macau Government</ListItem>
//								<ListItem>11/11/2019, <span style={{fontStyle: 'italic'}}>Data Privacy in Analysis,</span> (keynote speech), International Conference on Recent Trends in Advanced Computing (ICRTAC-2019), Chennai, India</ListItem>
//
//							</List>
//						</ListGroupWrap>
								</TabPane>
								<TabPane eventKey="awards-tab">
									<p>TBD</p>
								</TabPane>
								<TabPane eventKey="grants-tab">
									<p>TBD</p>
								</TabPane>
							</TabContent>
						</Tabs>
					</Col>
				</Row>
			</Container>
		</SectionWrap>
	)
}

DividerSection.propTypes = {
	teamMemberStyle: PropTypes.object,
	textStyle: PropTypes.object

}


DividerSection.defaultProps = {
	teamMemberStyle: {
		mb: '60px',
		responsive: {
			small: {
				mb: '30px'
			}
		}
	},
	HeadingStyle: {
		as: 'h5',
		mb: '30px'
	},
	ListThreeStyle: {
		layout: 'circle'
	},

	textStyle: {
		mt: '30px'
	},
	executiveStyle: {
		infoStyle: {
			textalign: "center"
		},
		nameStyle: {
			fontSize: '18px',
			lineHeight: 1.78
		}
	}
}

export default DividerSection
