import imageOne from "@data/images/team/songbaobao.jpg";


export const data = [
	{
		id: "baobao-song",
		name: "Baobao Song",
		designation: "PhD candidate, UTS",
		position: "head",
		image: imageOne,
		socials: {
            "scholar": "https://scholar.google.com/citations?user=JUxEGocAAAAJ&hl=en&oi=ao",
            "orcid": "https://github.com/tuliplab",
            "web": "/members/baobaosong"
        }

		
	}
]